import React from "react";
import { graphql } from 'gatsby';
import BlogDetails from "~sections/Blog/BlogDetails";
import { PageWrapper } from "~components/Core";
import FooterSection from "~sections/Blog/Footer";

export default function blogsDetails(props) {
  const {children, pageContext} = props;
  return (
    <PageWrapper innerPage={true}>
        <BlogDetails pageContext={pageContext}>
            {children}
        </BlogDetails>
        <FooterSection/>
    </PageWrapper>
  )
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(slug: { eq: $slug }) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
        date
      }
      body
    }
  }
`