import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "~components"
import Details from "./style"
import { StaticImage as Img } from "gatsby-plugin-image"
import Sidebar from "~sections/Common/Sidebar"
const formatter = new Intl.DateTimeFormat('en-US');

export default function BlogDetails({children, pageContext}) {
  const date = new Date();
  const frontmatter = pageContext.frontmatter;
  const dateStr = frontmatter.date;
  const expDateStr = dateStr.split("/");
  date.setMonth(+expDateStr[1] - 1);
  date.setDate(+expDateStr[2]);
  date.setFullYear(+expDateStr[0]);
  return (
    <Details backgroundColor="#f9fafc">
      <Details.Box pb="60px" pbMD="80px" pbLG="130px">
        <Container>
          <Row className="justify-content-center">
            <Col className="col-lg-7">
              <Details.Box mb="30px" mbLG="55px">
                <Details.Title as="h1">
                  {frontmatter.title}
                </Details.Title>
                <Details.MetaInfo>
                  By {frontmatter.author}
                  {formatter.format(date)}
                </Details.MetaInfo>
              </Details.Box>
              <Details.Box>
                {children}
              </Details.Box>
            </Col>
            <Col xs="12" className="col-xl-4 offset-xl-1 col-lg-5">
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </Details.Box>
    </Details>
  )
}
